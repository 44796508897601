"use client";

import React from "react";
import { LuInstagram, LuPhone } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "@/TranslationContext";

const Footer = () => {
  const router = useRouter();
  const t = useTranslations();

  const { locale } = router;

  const ls = locale === "sw" ? "" : `/${locale}`;
  const currentUrl = router.asPath;

  const plotsNavListItem = [
    {
      title: t.residentialPlots,
      link: `${ls}/listings?property_type=residential_plot`,
    },
    {
      title: t.commercialPlots,
      link: `${ls}/listings?property_type=industrial_plot`,
    },
    {
      title: t.farms,
      link: `${ls}/listings?property_type=farm`,
    },
    {
      title: t.payInInstallments,
      link: `${ls}/listings?payment_type=installments`,
    },
  ];

  const houseNavListItem = [
    {
      title: t.houseForRent,
      link: `${ls}/listings?listing_type=rent&property_type=house`,
    },
    {
      title: t.houseForSale,
      link: `${ls}/listings?listing_type=sale&property_type=house`,
    },
    {
      title: t.retailSpace,
      link: `${ls}/listings?listing_type=rent&property_type=retail_space`,
    },
    {
      title: t.officeSpace,
      link: `${ls}/listings?listing_type=rent&property_type=office_space`,
    },
  ];

  return (
    <footer className="bg-gray-800 text-gray-300 p-4">
      <div className="container mx-auto mt-6 ">
        <div className="mb-8 grid place-content-center">
          <div className="text-center border border-gray-400 rounded p-2">
            <span>{t.language} : </span>
            <Link
              href={currentUrl}
              locale="en"
              className={locale === "en" ? "font-bold" : ""}
            >
              English
            </Link>
            {" | "}
            <Link
              href={currentUrl}
              locale="sw"
              className={locale === "sw" ? "font-bold" : ""}
            >
              Swahili
            </Link>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1  gap-12">
          <div>
            <div>
              <h3 className="text-xl font-bold text-white">{t.aboutUs}</h3>
              {/* <Link href="/">
                <Image
                  src="/logo.png"
                  alt="MakaziMpaya"
                  width={72}
                  height={40}
                />
              </Link> */}
              <div
                className="text-gray-300 space-y-4 mt-5"
                dangerouslySetInnerHTML={{ __html: t.aboutUsContent }}
              ></div>
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold text-white">{t.plots}</h3>
            <ul className="mt-5 space-y-3">
              {plotsNavListItem.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.link}
                    className="hover:font-bold hover:text-[#1ED760] transition-all py-4"
                    title={item.title}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold text-white">{t.houses}</h3>
            <ul className="mt-5 space-y-3">
              {houseNavListItem.map((item, index) => (
                <li key={index}>
                  <a
                    href={item.link}
                    className="hover:font-bold hover:text-[#1ED760] transition-all py-4"
                    title={item.title}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold text-white">{t.contacts}</h3>
            <ul className="mt-5 space-y-3">
              <li>
                <div className="flex space-x-2 items-center">
                  <LuInstagram className="icons" />
                  <a
                    href="https://instagram.com/makazi.mapya"
                    target="_blank"
                    className="hover:font-bold hover:text-[#1ED760] transition-all"
                    title="Instagram"
                  >
                    Instagram
                  </a>
                </div>
              </li>
              <li>
                <div className="flex space-x-2 items-center">
                  <FaWhatsapp className="icons" />
                  <a
                    href={
                      "https://wa.me/+255692184008?text=Naomba maelezo zaidi kuhusu makazimapya.com"
                    }
                    target="_blank"
                    className="hover:font-bold hover:text-[#1ED760] transition-all "
                    title="WhatsApp"
                  >
                    WhatsApp
                  </a>
                </div>
              </li>
              <li>
                <div className="flex space-x-2 items-center">
                  <LuPhone className="icons" />
                  <a
                    href="tel:+255692184008"
                    target="_blank"
                    className="hover:font-bold hover:text-[#1ED760] transition-all "
                    title="Phone"
                  >
                    +255 692 184 008
                  </a>
                </div>
              </li>
            </ul>
            <div className="mt-10">
              <h3 className="text-xl font-bold text-white">{t.more}</h3>
              <ul className="mt-5 space-y-3">
                <li>
                  <a
                    href={`${ls}/locations`}
                    className="hover:font-bold hover:text-[#1ED760] transition-all py-4"
                    title={t.findByLocation}
                  >
                    {t.findByLocation}
                  </a>
                </li>
                <li>
                  <a
                    href={`sitemap.xml`}
                    className="hover:font-bold hover:text-[#1ED760] transition-all py-4"
                    title={t.findByLocation}
                  >
                    Site Map
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex mt-10 items-center py-4 justify-between">
          <div className="flex space-x-3">
            <a
              href="/privacy-policy"
              className="hover:font-bold hover:text-[#1ED760] transition-all"
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
            <a
              href="/terms"
              className="hover:font-bold hover:text-[#1ED760] transition-all"
              title="Terms of Service"
            >
              Terms of Service
            </a>
          </div>
          <div>© 2023 Makazi Mapya - All rights reserved.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
